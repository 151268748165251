function stackPapers() {
	const stacks = document.querySelectorAll<HTMLElement>('.stack-of-papers');

	stacks.forEach(stack => {
		const sheets = stack.querySelectorAll<HTMLElement>('.sheet-of-paper');
		sheets.forEach((sheet, i) => {
			const sentinel = document.createElement('div');
			sentinel.classList.add('sentinel');
			sheet.parentElement?.insertBefore(sentinel, sheet);
			sheet.style.zIndex = String(sheets.length - i);
		});
		handleViewportChange();
	});

	function handleViewportChange() {
		stacks.forEach(stack => {
			const stackRect = stack.getBoundingClientRect();
			const scrolled = -1 * stackRect.top / (stackRect.height - innerHeight);
			const effect = Math.min(1, (1 - Math.max(0, Math.min(1, scrolled))) * 2);
			stack.style.setProperty('--effect', String(effect));

			const sheets = stack.querySelectorAll<HTMLElement>('.sheet-of-paper');
			sheets.forEach(sheet => {
				const sentinel = sheet.previousElementSibling;
				if (!(sentinel instanceof HTMLElement)) return;
				const sheetStyle = getComputedStyle(sheet);
				sentinel.style.width = `calc(${sheet.offsetWidth}px + ${sheetStyle.marginInlineStart || 0} + ${sheetStyle.marginInlineEnd || 0})`;
				sentinel.style.height = `calc(${sheet.offsetHeight}px + ${sheetStyle.marginBlockStart || 0} + ${sheetStyle.marginBlockEnd || 0})`;
				const sentinelRect = sentinel.getBoundingClientRect();
				const normalTop = Math.max(stackRect.top, Math.min(sentinelRect.top, 0));
				const weightedTop = (normalTop * effect) + (sentinelRect.top * (1 - effect));
				sheet.style.top = `${weightedTop}px`;
			});
		});
	}

	addEventListener('scroll', handleViewportChange);
	addEventListener('resize', handleViewportChange);
	document.addEventListener('load', handleViewportChange, true); // For when images load
}

stackPapers();

function applyTilts() {
	const selector = '.sheet-of-paper, .photo';
	const elements = document.querySelectorAll<HTMLElement>(selector);
	elements.forEach(element => {
		const oldTilt = parseFloat(element.style.getPropertyValue('--tilt') || '0');
		const newTilt = oldTilt + Math.random() * 4 - 2;
		element.style.setProperty('--tilt', newTilt.toFixed(2));
	});
}

applyTilts();

addEventListener('click', event => {
	if (event.target instanceof Element) {
		if (event.target.closest('a')) return;
		if (event.target.closest('button')) return;
	}

	applyTilts();
});
